import(/* webpackMode: "eager", webpackExports: ["GlobalProviders"] */ "/vercel/path0/apps/tools/src/app/[locale]/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-axiom@1.4.1_next@14.2.8_@babel+core@7.25.2_babel-plugin-macros@3.1.0_react-dom@18.3.1_re_edcoqycuygyvvckpm7oaph7r5y/node_modules/next-axiom/dist/webVitals/components.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_@babel+core@7.25.2_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.78.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Raleway\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/tools/src/app/[locale]/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["ClientFlagsProvider"] */ "/vercel/path0/packages/flags/src/client/client-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/create-use-flag.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-admins-toolers-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-app-link-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-archive-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-configuration-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-diagnostic-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-payment-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-resources-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-sidebar-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-tags-feature.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/flags/src/client/use-tools-talents-invite-feature.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["I18nClientProvider"] */ "/vercel/path0/packages/shared/i18n/src/provider/i18n-client.provider.tsx");
